import styled from '@emotion/styled'
import { styled as muiStyled } from '@mui/material/styles'
import { TableHead } from '@mui/material'

export const StyledTableHead = muiStyled(TableHead)`
    border: none;
`

export const PolicyStatusHeader = styled.h4`
  margin: 0 25px 0 0;
  //TODO: take color from theme
  color: gray;
`

export const PolicyStatusCompliant = styled.h4`
  margin: 0;
  font-size: 22px;
`

export const PolicyStatusViolations = styled.h4`
  //text-align: center;
  margin: 0;
  font-size: 22px;
  //TODO: take color from theme
  color: red;
`
