import React, { useEffect, useMemo, useState } from 'react'
import dic from '../../../dictionary'
import _ from 'lodash'
import { Grid, Box, IconButton } from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro'
import { ArrowBack, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { getAxiosInstance } from '../../../axiosInstance'
import dayjs from 'dayjs'
import { ROUTES, TAGS_DELIMITER, DATAGRID_PROPS } from '../../../consts'
import ComponentTags from '../../commons/componentTags'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../loader'
import { TechnologyIcon } from './specificTechnologyCatalog.style'

const K8S_ACTIVE_STATUS = 'active'
const K8S_TECHNOLOGIES = ['eks', 'gke']

const TechnologyCatalog = () => {
  const navigate = useNavigate()
  const { technologyName } = useParams<{ technologyName: string }>()
  const [technologyDisplayName, setTechnologyDisplayName] = useState('')
  const [components, setComponents] = useState<any[]>([])
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const technologyComponents = await getAxiosInstance().get('/component', { params: { type: technologyName } })
        const integrations = await getAxiosInstance().get('/integration')
        const parsedComponents = _.map(technologyComponents.data, (component) => {
          let integration = _.find(integrations.data, (integration) => component.integrationId === integration.id)
          let explorerIntegration = _.find(integrations.data, (integration) => _.get(integration, 'details.clusterId') === component.uniqueIdentifier)
          return {
            ...component,
            integrationName: _.get(integration, 'name'),
            isActive:
              technologyName && K8S_TECHNOLOGIES.includes(technologyName)
                ? _.get(explorerIntegration, 'details.status') === K8S_ACTIVE_STATUS
                : undefined,
          }
        })
        setComponents(parsedComponents)
        setTechnologyDisplayName(_.get(technologyComponents.data, '[0].displayName'))
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchData()
  }, [technologyName])

  const rows = useMemo(
    () =>
      _.map(components, (component) => ({
        id: component.id,
        uniqueIdentifier: component.uniqueIdentifier,
        technology: component.technology,
        region: component.region,
        version: component.version,
        isActive: component.isActive ? 'Yes' : 'No',
        integrationName: component.integrationName,
        updatedAt: dayjs(component.updated_at).format('MMM D, YYYY'),
        tags: component.details?.tags || [],
        ..._.reduce(
          _.get(component, 'tags', []),
          (acc: any, tag) => {
            acc[`${TAGS_DELIMITER}${_.get(tag, 'key')}`] = _.get(tag, 'value')
            return acc
          },
          {},
        ),
      })),
    [components],
  )

  const tagsFilters: GridColDef[] = useMemo(() => {
    const allTags = _.sortBy(_.uniq(_.flatMap(components, (component: any) => _.map(_.get(component, 'details.tags', []), (tag: any) => tag.key))), [
      (item) => item.toLowerCase(),
    ])

    return _.map(allTags, (tag) => ({
      field: `${TAGS_DELIMITER}${tag}`,
      headerName: tag,
      flex: 2,
      hide: true,
      renderCell: (params: any) => {
        return _.get(
          _.find(params.row.tags, (t: any) => t.key === tag),
          'value',
          '',
        )
      },
    }))
  }, [components])

  const columns: GridColDef[] = [
    { field: 'uniqueIdentifier', headerName: dic.catalog.specific_technology.table.name, flex: 2 },
    { field: 'technology', headerName: dic.catalog.specific_technology.table.technology, flex: 1 },
    { field: 'region', headerName: dic.catalog.specific_technology.table.region, flex: 1 },
    { field: 'version', headerName: dic.catalog.specific_technology.table.version, flex: 1 },
    ...tagsFilters,
  ]

  if (technologyName && K8S_TECHNOLOGIES.includes(technologyName)) {
    columns.splice(4, 0, {
      field: 'isActive',
      headerName: dic.catalog.specific_technology.table.active_integration,
      flex: 1,
    })
  }

  const getDetailPanelContent = (params: any) => (
    <Grid container margin={2} spacing={1} flexDirection="column" justifyContent="space-between" alignItems="flex-start">
      <Grid item>
        <strong>{dic.catalog.specific_technology.table.integration_name}:</strong> {params.row.integrationName}
      </Grid>
      <Grid item>
        <strong>{dic.catalog.specific_technology.table.updated_at}:</strong> {params.row.updatedAt}
      </Grid>
      {params.row.tags.length > 0 && <ComponentTags tags={params.row.tags} />}
    </Grid>
  )

  const initialVisibilityModel: GridColumnVisibilityModel = useMemo(() => {
    return _.reduce(
      tagsFilters,
      (model: GridColumnVisibilityModel, col) => {
        model[col.field] = false
        return model
      },
      {} as GridColumnVisibilityModel,
    )
  }, [tagsFilters])

  const onBackIconClick = () => {
    navigate(ROUTES.CATALOG.ALL)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
        <IconButton onClick={onBackIconClick}>
          <ArrowBack />
        </IconButton>
        <TechnologyIcon src={`https://draftt-public.s3.amazonaws.com/icons/${technologyName}.svg`} alt={technologyDisplayName} />
        <h1>
          {dic.catalog.specific_technology.title} / {technologyDisplayName} ({components.length})
        </h1>
      </Box>
      <Grid container>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          disableRowSelectionOnClick
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          slots={{
            detailPanelExpandIcon: KeyboardArrowDown,
            detailPanelCollapseIcon: KeyboardArrowUp,
            toolbar: () => (
              <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
              </GridToolbarContainer>
            ),
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'uniqueIdentifier', sort: 'asc' }],
            },
            columns: {
              columnVisibilityModel: initialVisibilityModel,
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default TechnologyCatalog
