import React from 'react'
import dic from '../../../../../../../dictionary'
import { Grid, Link, Typography } from '@mui/material'
import CodeBlock from '../../../../../../commons/codeBlock'
import { IntegrationQuote } from '../../../../../integrations.style'

const AwsIntegrationCloudFormationInstructions = (props: any) => {
  return (
    <Grid>
      <Grid container flexDirection="column" alignItems="start">
        <Typography variant="subtitle1"> {dic.integrations.instructions.aws.cloud_formation.connect} </Typography>
        <h3>{dic.integrations.instructions.aws.cloud_formation.step_1.title}</h3>
        <Grid container flexDirection="row" alignItems="center">
          <Typography variant="subtitle1">{dic.integrations.instructions.aws.cloud_formation.step_1.go_to}&nbsp;</Typography>
          <Link target="blank" href="https://us-east-1.console.aws.amazon.com/cloudformation/home/stacks/create">
            {dic.integrations.instructions.aws.cloud_formation.step_1.cloud_formation}
          </Link>
        </Grid>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation.step_1.click_on}
          <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation.step_1.create_stack} </IntegrationQuote>
          {dic.integrations.instructions.aws.cloud_formation.step_1.new_resources}
        </Typography>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation.step_1.upload_template}
          <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation.step_1.choose_existing_template} </IntegrationQuote>
        </Typography>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation.step_1.specify_template}
          <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation.step_1.amazon_s3_url} </IntegrationQuote>
          {dic.integrations.instructions.aws.cloud_formation.step_1.provide_link}
        </Typography>
        <Grid>
          <CodeBlock code={dic.integrations.instructions.aws.cloud_formation.step_1.draftt_cloud_formation_link}></CodeBlock>
        </Grid>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation.step_1.now_press}
          <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation.step_1.next}</IntegrationQuote>
        </Typography>
      </Grid>

      <Grid container flexDirection="column" alignItems="start">
        <h3>{dic.integrations.instructions.aws.cloud_formation.step_2.title}</h3>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation.step_2.name}
          <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation.step_2.draftt_stack}</IntegrationQuote>
        </Typography>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation.step_2.recommendation}
          <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation.step_2.draftt_role_name}</IntegrationQuote>
          {dic.integrations.instructions.aws.cloud_formation.step_2.parameter}
        </Typography>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation.step_2.on_the}
          <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation.step_2.external_id} </IntegrationQuote>
          {dic.integrations.instructions.aws.cloud_formation.step_2.following_value}
        </Typography>
        <Grid>
          <CodeBlock code={props.externalId}></CodeBlock>
        </Grid>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation.step_2.now_press}
          <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation.step_2.next}</IntegrationQuote>
        </Typography>
      </Grid>

      <Grid container flexDirection="column" alignItems="start">
        <h3>{dic.integrations.instructions.aws.cloud_formation.step_3.title}</h3>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation.step_3.default}
          <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation.step_3.next}</IntegrationQuote>
        </Typography>
      </Grid>

      <Grid container flexDirection="column" alignItems="start">
        <h3>{dic.integrations.instructions.aws.cloud_formation.step_4.title}</h3>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation.step_4.review}
          <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation.step_4.acknowledge} </IntegrationQuote>
          {dic.integrations.instructions.aws.cloud_formation.step_4.statement}
        </Typography>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation.step_4.now_press}
          <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation.step_4.submit}</IntegrationQuote>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AwsIntegrationCloudFormationInstructions
