import React, { useEffect, useState } from 'react'
import dic from '../../dictionary'
import { getAxiosInstance } from '../../axiosInstance'
import Loader from '../loader'
import _ from 'lodash'
import { Box, Grid, Tab, Tabs } from '@mui/material'
import DrafttsTable from './table'
import { DRAFTT_STATUSES } from '../../consts'

const Draftts = () => {
  const [tabValue, setTabValue] = useState(0)
  const [activeDraftts, setActiveDrafttsValue] = useState<any[]>([])
  const [completedDraftts, setCompletedDrafttsValue] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const draftts = await getAxiosInstance().get(`/draftt`)
        setActiveDrafttsValue(_.filter(draftts.data, { status: DRAFTT_STATUSES.ACTIVE }))
        setCompletedDrafttsValue(_.filter(draftts.data, { status: DRAFTT_STATUSES.COMPLETED }))
        setLoading(false)
      } catch (error) {
        setLoading(false)
        // setError('Error fetching data');
      }
    }
    fetchData()
  }, [])

  if (loading) {
    return <Loader />
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Grid>
      <h1>{dic.draftts.title}</h1>

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Tabs" sx={{ paddingBottom: '20px' }}>
        <Tab label={`${dic.draftts.tabs.active} (${activeDraftts.length})`} />
        <Tab label={`${dic.draftts.tabs.completed} (${completedDraftts.length})`} />
      </Tabs>

      <Box>
        {tabValue === 0 && <DrafttsTable draftts={activeDraftts} />}
        {tabValue === 1 && <DrafttsTable draftts={completedDraftts} />}
      </Box>
    </Grid>
  )
}

export default Draftts
