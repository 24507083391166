import React from 'react'
import dic from '../../../../../../dictionary'
import { Grid, Link, Typography } from '@mui/material'
import CodeBlock from '../../../../../commons/codeBlock'
import { CODE_BLOCK_FORMATS } from '../../../../../../consts'
import _ from 'lodash'
import { IntegrationQuote } from '../../../../integrations.style'

const CUSTOM_TRUST_POLICY = {
  Version: '2012-10-17',
  Statement: [
    {
      Effect: 'Allow',
      Principal: {
        AWS: 'arn:aws:iam::339712924365:role/draftt-fetcher',
      },
      Action: 'sts:AssumeRole',
      Condition: {
        StringEquals: {
          'sts:ExternalId': '<YOUR_EXTERNAL_ID_PROVIDED_BY_DRAFTT>',
        },
      },
    },
  ],
}

const AwsIntegrationManualInstallationInstructions = (props: any) => {
  return (
    <Grid>
      <Grid container flexDirection="column" alignItems="start">
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.manual.connect}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.custom_trust_policy}</IntegrationQuote>
          {dic.integrations.instructions.aws.manual.called}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.drafft_access_role}</IntegrationQuote>
        </Typography>
        <h3>{dic.integrations.instructions.aws.manual.step_1.title}</h3>
        <Link target="blank" href="https://us-east-1.console.aws.amazon.com/iam/home?region=us-east-1#/policies/create">
          {dic.integrations.instructions.aws.manual.step_1.create}
        </Link>
        <Typography variant="subtitle1">{dic.integrations.instructions.aws.manual.step_1.switch_to_json} </Typography>
        <Grid>
          <CodeBlock code={dic.integrations.instructions.aws.manual.step_1.draftt_policy_link} />
        </Grid>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.manual.step_1.click_on}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.step_1.actions}</IntegrationQuote>
          {dic.integrations.instructions.aws.manual.step_1.then_select}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.step_1.optimize_for_size}</IntegrationQuote>
        </Typography>
        <Typography>
          {dic.integrations.instructions.aws.manual.step_1.click_on_next}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.step_1.next}</IntegrationQuote>
        </Typography>
      </Grid>

      <Grid container flexDirection="column" alignItems="start">
        <h3>{dic.integrations.instructions.aws.manual.step_2.title}</h3>
        <Typography variant="subtitle1">{dic.integrations.instructions.aws.manual.step_2.name} </Typography>
        <Grid>
          <CodeBlock code={dic.integrations.instructions.aws.manual.step_2.draftt_policy_name} format={CODE_BLOCK_FORMATS.text} />
        </Grid>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.manual.step_2.click_on}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.step_2.create_policy}</IntegrationQuote>
        </Typography>
      </Grid>

      <Grid container flexDirection="column" alignItems="start">
        <h3>{dic.integrations.instructions.aws.manual.step_3.title}</h3>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.manual.step_3.navigate_to}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.step_3.roles}</IntegrationQuote>
          {dic.integrations.instructions.aws.manual.step_3.and_click_on}
          <Link target="blank" href="https://us-east-1.console.aws.amazon.com/iam/home?region=us-east-1#/roles/create">
            {dic.integrations.instructions.aws.manual.step_3.create_role}
          </Link>
        </Typography>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.manual.step_3.on_the}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.step_3.trusted_entity_type}</IntegrationQuote>
          {dic.integrations.instructions.aws.manual.step_3.section_choose}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.step_3.custom_trust_policy}</IntegrationQuote>
        </Typography>
        <Typography variant="subtitle1">{dic.integrations.instructions.aws.manual.step_3.copy_policy} </Typography>
        <Grid>
          <CodeBlock
            code={_.set(CUSTOM_TRUST_POLICY, 'Statement[0].Condition.StringEquals["sts:ExternalId"]', props.externalId)}
            format={CODE_BLOCK_FORMATS.json}
          />
        </Grid>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.manual.step_3.click_on}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.step_3.next}</IntegrationQuote>
        </Typography>
      </Grid>

      <Grid container flexDirection="column" alignItems="start">
        <h3>{dic.integrations.instructions.aws.manual.step_4.title}</h3>
        <Typography variant="subtitle1">{dic.integrations.instructions.aws.manual.step_4.attach} </Typography>
        <Typography variant="subtitle1">
          <IntegrationQuote>&#8226;&nbsp;{dic.integrations.instructions.aws.manual.step_4.policy_1}</IntegrationQuote>
        </Typography>
        <Typography variant="subtitle1">
          <IntegrationQuote>&#8226;&nbsp;{dic.integrations.instructions.aws.manual.step_4.policy_2} </IntegrationQuote>
        </Typography>
        <Typography variant="subtitle1">
          <IntegrationQuote>&#8226;&nbsp;{dic.integrations.instructions.aws.manual.step_4.policy_3} </IntegrationQuote>
        </Typography>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.manual.step_4.click_on}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.step_4.next}</IntegrationQuote>
        </Typography>
      </Grid>

      <Grid container flexDirection="column" alignItems="start">
        <h3>{dic.integrations.instructions.aws.manual.step_5.title}</h3>
        <Typography variant="subtitle1">{dic.integrations.instructions.aws.manual.step_5.name} </Typography>
        <Grid>
          <CodeBlock code={dic.integrations.instructions.aws.manual.step_5.role_default_name} format={CODE_BLOCK_FORMATS.text} />
        </Grid>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.manual.step_5.review}
          <IntegrationQuote>{dic.integrations.instructions.aws.manual.step_5.create_role}</IntegrationQuote>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AwsIntegrationManualInstallationInstructions
