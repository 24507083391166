import React from 'react'
import { Snackbar, Alert } from '@mui/material'
import { NOTIFICATION_TYPES } from '../../../consts'

interface NotificationProps {
  open: boolean
  message: string
  severity: NOTIFICATION_TYPES.ERROR | NOTIFICATION_TYPES.INFO | NOTIFICATION_TYPES.WARNING | NOTIFICATION_TYPES.SUCCESS
  onClose: () => void
}

const AUTO_HIDE_DURATION = 5000

const Notification = (props: NotificationProps) => {
  return (
    <Snackbar open={props.open} autoHideDuration={AUTO_HIDE_DURATION} onClose={props.onClose}>
      <Alert onClose={props.onClose} severity={props.severity} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}

export default Notification
