import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react'
import Notification from './index'
import { NOTIFICATION_TYPES } from '../../../consts'

interface NotificationState {
  open: boolean
  message: string
  severity: NOTIFICATION_TYPES.ERROR | NOTIFICATION_TYPES.INFO | NOTIFICATION_TYPES.WARNING | NOTIFICATION_TYPES.SUCCESS
}

interface NotificationContextType {
  showNotification: (message: string, severity?: NotificationState['severity']) => void
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined)

export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider')
  }
  return context
}

interface NotificationProviderProps {
  children: ReactNode
}

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [notification, setNotification] = useState<NotificationState>({
    open: false,
    message: '',
    severity: NOTIFICATION_TYPES.SUCCESS,
  })

  const showNotification = useCallback((message: string, severity: NotificationState['severity'] = NOTIFICATION_TYPES.SUCCESS) => {
    setNotification({
      open: true,
      message,
      severity,
    })
  }, [])

  const closeNotification = useCallback(() => {
    setNotification((prev) => ({
      ...prev,
      open: false,
    }))
  }, [])

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <Notification open={notification.open} message={notification.message} severity={notification.severity} onClose={closeNotification} />
    </NotificationContext.Provider>
  )
}
