import React, { useEffect, useState } from 'react'
import dic from '../../../../dictionary'
import _ from 'lodash'
import { Grid, Paper } from '@mui/material'
import { getAxiosInstance } from '../../../../axiosInstance'
import Loader from '../../../loader'
import { DRAFTT_STATUSES, INTEGRATIONS } from '../../../../consts'
import MetricSummaryCard from './components/metricSummaryCard'
import { WidgetTitle } from '../../../../common.css'
import { Check, CloudOutlined, IntegrationInstructionsOutlined, PolicyOutlined, Timeline } from '@mui/icons-material'
import { MetricIcon } from './metricsSummary.style'

const MetricsSummary = (props: any) => {
  const allComponents = props.components
  const allDraftts = props.draftts
  const allPolicies = props.policies
  const allIntegrations = props.integrations

  const [k8sComponentsData, setK8sComponentsData] = useState([])
  const [metricsData, setMetricsData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const calculateMetricsData = () => {
    const completedDraftts = _.filter(allDraftts, { status: DRAFTT_STATUSES.COMPLETED })
    const activeDraftts = _.filter(allDraftts, { status: DRAFTT_STATUSES.ACTIVE })
    const k8sIntegrations = _.filter(allIntegrations, { type: INTEGRATIONS.k8sExplorer.type })

    return [
      {
        title: dic.dashboard.metrics_summary.metrics.total_cloud_components,
        value: allComponents.length,
        icon: (
          <MetricIcon>
            <CloudOutlined fontSize="small" />
          </MetricIcon>
        ),
      },
      {
        title: dic.dashboard.metrics_summary.metrics.active_draftts,
        value: activeDraftts.length,
        icon: (
          <MetricIcon>
            <Timeline fontSize="small" />
          </MetricIcon>
        ),
      },
      {
        title: dic.dashboard.metrics_summary.metrics.completed_draftts,
        value: completedDraftts.length,
        icon: (
          <MetricIcon>
            <Check fontSize="small" />
          </MetricIcon>
        ),
      },
      {
        title: dic.dashboard.metrics_summary.metrics.policies,
        value: allPolicies.length,
        icon: (
          <MetricIcon>
            <PolicyOutlined fontSize="small" />
          </MetricIcon>
        ),
      },
      {
        title: dic.dashboard.metrics_summary.metrics.k8s_integrations,
        value: k8sIntegrations.length,
        icon: (
          <MetricIcon>
            <IntegrationInstructionsOutlined fontSize="small" />
          </MetricIcon>
        ),
      },
      {
        title: dic.dashboard.metrics_summary.metrics.k8s_components,
        value: k8sComponentsData.length,
        icon: (
          <MetricIcon>
            <img src={`https://draftt-public.s3.amazonaws.com/icons/kubernetes.svg`} alt={dic.dashboard.metrics_summary.metrics.active_draftts} />
          </MetricIcon>
        ),
      },
    ]
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const k8sActiveK8sComponents = await getAxiosInstance().get(`/component/k8s/active`)
        setK8sComponentsData(k8sActiveK8sComponents.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        // TODO: handle error
        // navigate(ROUTES.POLICIES.ALL)
        // setError('Error fetching data');
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    setMetricsData(calculateMetricsData())
  }, [allComponents, allDraftts, allPolicies, allIntegrations, k8sComponentsData])

  if (loading) {
    return <Loader />
  }

  return (
    <Paper variant="outlined" sx={{ p: '16px', ml: '16px', height: '100%' }}>
      <Grid sx={{ mb: '16px' }}>
        <WidgetTitle>{dic.dashboard.metrics_summary.title}</WidgetTitle>
      </Grid>
      <Grid container spacing={2}>
        {_.map(metricsData, (metric, index) => (
          <MetricSummaryCard metric={metric} key={index} />
        ))}
      </Grid>
    </Paper>
  )
}

export default MetricsSummary
