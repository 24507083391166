import { Paper, Grid, Typography } from '@mui/material'
import React from 'react'

const MetricSummaryCard = (props: any) => {
  return (
    <Grid item xs={6}>
      <Paper sx={{ p: '20px' }} variant="outlined">
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Grid container alignItems="center" justifyContent="center">
            <Grid item sx={{ pr: '16px' }}>
              {props.metric.icon}
            </Grid>
            <Grid item sx={{ minWidth: '120px' }}>
              <Typography variant="subtitle2">{props.metric.value}</Typography>
              <Typography variant="body2">{props.metric.title}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default MetricSummaryCard
