import CircularProgress from '@mui/material/CircularProgress'
import { LoaderContainer } from './loader.style'

const Loader = () => {
  return (
    <LoaderContainer container justifyContent="center" alignItems="center">
      <CircularProgress color="primary" />
    </LoaderContainer>
  )
}

export default Loader
