export const ROUTES = {
  DASHBOARD: '/dashboard',
  POLICIES: {
    ALL: '/policies',
    SINGLE: '/policy/:policyId',
  },
  CATALOG: {
    ALL: '/catalog',
    SPECIFIC_TECHNOLOGY: '/catalog/:technologyName',
  },
  SETTINGS: {
    ACCESS_KEYS: '/access-keys',
    ACCOUNT: '/account',
  },
  DRAFTTS: {
    ALL: '/draftt',
    DETAILS: '/draftt/:drafttId',
  },
  INTEGRATIONS: {
    ALL: '/integrations',
    BY_TYPE: '/integrations/:type',
    CREATE: '/integrations/:type/create',
  },
}

export const INTEGRATIONS = {
  aws: {
    type: 'aws',
    title: 'Amazon Web Services',
    description:
      'Draftt integrates with your Amazon Web Services (AWS) account to identify your tech stack and help you prioritize your impending maintenance efforts',
  },
  k8sExplorer: {
    type: 'k8s-explorer',
    title: 'Draftt Explorer',
    description:
      'Draftt integrates with your Kubernetes (K8s) clusters to analyze your resources, nodes, and Helm charts. It detects compatibility issues between Kubernetes versions and deployed applications or services, and provides recommendations for upgrades and mitigations.',
  },
  mongodbAtlas: {
    type: 'mongodb-atlas',
    title: 'MongoDB Atlas',
    description: 'Draftt integrates with your MongoDB account to identify your tech stack and help you prioritize your impending maintenance efforts',
  },
}

export const INTEGRATIONS_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

export const POLISHED_LIGHTEN = 0.2
export const POLISHED_DARKEN = 0.05

export const theme = {
  dark_purple: '#262767',
  purple: '#4A41A8',
}

export const SIDEBAR_WIDTH = '220px'

export const CODE_BLOCK_FORMATS = {
  json: 'json',
  text: 'text',
}

export const DRAFTT_STATUSES = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  CHANGE: {
    PENDING: 'pending',
    COMPLETED: 'completed',
    WARNING: 'warning',
    ACKNOWLEDGED: 'acknowledged',
    INFO: 'info',
  },
}

export enum NOTIFICATION_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export const DEFAULT_POLICIES_IDS = {
  EOL: '1',
  LATEST_AND_GREATEST: '2',
}

export enum SORTING_OPTIONS {
  ASC = 'asc',
  DESC = 'desc',
}

export const TAGS_DELIMITER = ' : '

export const DATAGRID_PROPS = {
  ROW_HEIGHT: 52,
  BASE_HEIGHT: 151,
}
