import React, { useRef, useState, useEffect } from 'react'
import { Tooltip } from '@mui/material'
import { EllipsisCell } from './ellipsisTableCell.style'

const EllipsisTableCell: React.FC<{ value: string }> = ({ value }) => {
  const textRef = useRef<HTMLDivElement>(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth)
      }
    }
    checkTruncation()
    window.addEventListener('resize', checkTruncation)
    return () => {
      window.removeEventListener('resize', checkTruncation)
    }
  }, [value])

  return (
    <Tooltip title={value} disableHoverListener={!isTruncated}>
      <EllipsisCell ref={textRef}>{value}</EllipsisCell>
    </Tooltip>
  )
}

export default EllipsisTableCell
