import React from 'react'
import { Box, Chip, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import dic from '../../../dictionary'
import _ from 'lodash'
import { TAGS_DELIMITER } from '../../../consts'

const ComponentTags = (props: any) => {
  const tagsArray = _.map(props.tags, ({ key, value }) => `${key}${TAGS_DELIMITER}${value}`)

  return (
    <Grid container flexDirection="row" alignItems="center" sx={{ pt: '2px', pl: '8px' }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 700, mb: '3px' }}>
        {dic.components.common.tags.title}:&nbsp;
      </Typography>
      {_.map(tagsArray, (tag) => (
        <Box key={tag} mr={1}>
          <Chip label={tag} sx={{ mb: '3px' }} />
        </Box>
      ))}
    </Grid>
  )
}

export default ComponentTags
