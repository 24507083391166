import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dic from '../../dictionary'
import { ROUTES, TAGS_DELIMITER } from '../../consts'
import { getAxiosInstance } from '../../axiosInstance'
import Loader from '../loader'
import _ from 'lodash'
import { Box, Grid, IconButton, Tab, Tabs } from '@mui/material'
import { ArrowBack, AutoAwesome } from '@mui/icons-material'
import SuggestedTable from './components/suggestedTable'
import CompliantTable from './components/compliantTable'
import ViolationsTable from './components/violationsTable'
import FutureTimeline from './components/futureTimeline'
import { ContainerBox } from '../../common.css'
import { FutureTimelineTitle } from './policy.style'
import { GridColDef } from '@mui/x-data-grid'

const Policy = () => {
  const { policyId } = useParams<{ policyId: string }>()
  const navigate = useNavigate()

  const [tabValue, setTabValue] = useState(0)
  const [violationsPolicyComponents, setViolationsPolicyComponentsValue] = useState<any[]>([])
  const [compliantPolicyComponents, setCompliantPolicyComponentsValue] = useState<any[]>([])
  const [policyComponentsData, setPolicyComponentsData] = useState([])
  const [policy, setPolicy] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const policy = await getAxiosInstance().get(`/policy/${policyId}`)
        setPolicy(policy.data)

        if (!policy.data.id) {
          navigate(ROUTES.POLICIES.ALL)
        } else {
          const policyComponents = await getAxiosInstance().get('/policyComponent', {
            params: {
              policyId,
            },
          })

          setViolationsPolicyComponentsValue(_.filter(policyComponents.data, { isCompliant: false }))
          setCompliantPolicyComponentsValue(_.filter(policyComponents.data, { isCompliant: true }))
          setPolicyComponentsData(policyComponents.data)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        navigate(ROUTES.POLICIES.ALL)
        // setError('Error fetching data');
      }
    }
    fetchData()
  }, [])

  const tagsFilters: GridColDef[] = useMemo(() => {
    const allTags = _.sortBy(_.uniq(_.flatMap(policyComponentsData, (component: any) => _.map(component.tags, (tag: any) => tag.key))), [
      (item) => item.toLowerCase(),
    ])

    return _.map(allTags, (tag) => ({
      field: `${TAGS_DELIMITER}${tag}`,
      headerName: tag,
      flex: 2,
      hide: true,
      renderCell: (params: any) => {
        return _.get(
          _.find(params.row.tags, (t: any) => t.key === tag),
          'value',
          '',
        )
      },
    }))
  }, [policyComponentsData])

  if (!policyId) {
    navigate(ROUTES.POLICIES.ALL)
  }

  if (loading) {
    return <Loader />
  }

  const onBackIconClick = () => {
    navigate(ROUTES.POLICIES.ALL)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
        <IconButton onClick={onBackIconClick}>
          <ArrowBack />
        </IconButton>
        <h1>
          {dic.policies_title} / {_.get(policy, 'name')}{' '}
        </h1>
      </Box>
      {policyId && policyId == '1' && (
        <ContainerBox>
          <FutureTimelineTitle> {dic.future_timeline.title} </FutureTimelineTitle>
          <FutureTimeline policyComponents={policyComponentsData} />
        </ContainerBox>
      )}
      <Tabs value={tabValue} onChange={handleChange} aria-label="Tabs" sx={{ paddingBottom: '20px', paddingTop: '20px' }}>
        <Tab icon={<AutoAwesome fontSize="small" />} iconPosition="start" label={dic.policy_tabs.suggested_draftts} />
        <Tab label={`${dic.policy_tabs.violations} (${violationsPolicyComponents.length})`} />
        <Tab label={`${dic.policy_tabs.compliant} (${compliantPolicyComponents.length})`} />
      </Tabs>

      <Grid container>
        {tabValue === 0 && <SuggestedTable policyComponents={policyComponentsData} tagsFilters={tagsFilters} />}
        {tabValue === 1 && <ViolationsTable violationsPolicyComponents={violationsPolicyComponents} tagsFilters={tagsFilters} />}
        {tabValue === 2 && <CompliantTable compliantPolicyComponents={compliantPolicyComponents} tagsFilters={tagsFilters} />}
      </Grid>
    </Grid>
  )
}

export default Policy
