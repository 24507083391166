import React, { useState } from 'react'
import _ from 'lodash'
import dic from '../../../../dictionary'
import { Box, Step, Stepper, Typography, IconButton, Grid } from '@mui/material'
import { CustomStepLabel, StepTitle } from './futureTimeline.style'
import { ArrowBackIos, ArrowForwardIos, Circle } from '@mui/icons-material'
import dayjs from 'dayjs'

const NUM_OF_EVENTS = 5 // Number of events to display at a time
const VERSIONS_CHAR_LIMIT = 30

const FutureTimeline = (props: any) => {
  const [currentStep, setCurrentStep] = useState(0)

  // Step 1: Filter components with non-null desiredVersion and dueDate
  const policyComponents = _.filter(
    props.policyComponents,
    (policyComponent) => policyComponent.desiredVersion != null && policyComponent.dueDate != null,
  )

  // Step 3: Sort components by dueDate
  const sortedPolicyComponents = _.sortBy(policyComponents, (policyComponent) => policyComponent.dueDate)

  // Step 4: Filter components with a future due date
  const filteredFutureComponents = _.filter(sortedPolicyComponents, (policyComponent) => {
    return dayjs(policyComponent.dueDate).isAfter(dayjs())
  })

  // Step 5: Group by technology and dueDate using Lodash's groupBy
  const groupedComponents = _.groupBy(
    filteredFutureComponents,
    (component) => `${component.technology}-${dayjs(component.dueDate).format('YYYY-MM-DD')}`,
  )

  // Step 6: Take the events to display
  const displayGroups = _.entries(groupedComponents)

  // Get the visible groups based on the current step
  const visibleGroups = displayGroups.slice(currentStep, currentStep + NUM_OF_EVENTS)

  const handleNext = () => {
    if (currentStep + NUM_OF_EVENTS < displayGroups.length) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  return (
    <Grid container flexDirection="row" alignItems="center" justifyContent="center">
      {displayGroups.length > NUM_OF_EVENTS && (
        <Grid container item xs={1} alignItems="center" justifyContent="center">
          <IconButton onClick={handleBack} disabled={currentStep === 0}>
            <ArrowBackIos />
          </IconButton>
        </Grid>
      )}

      <Grid container item xs={displayGroups.length > NUM_OF_EVENTS ? 10 : 12} alignItems="center" justifyContent="center">
        <Stepper activeStep={-1} alternativeLabel sx={{ width: '100%' }}>
          {_.map(visibleGroups, ([groupKey, components]: [string, any[]]) => {
            const firstComponent = _.first(components)
            const dueDate = firstComponent?.dueDate

            const versions = _.uniq(_.map(components, (component) => `${component.currentVersion}`))
            const versionsString = versions.join(', ')

            const displayText = versionsString.length > VERSIONS_CHAR_LIMIT ? `${versionsString.slice(0, VERSIONS_CHAR_LIMIT)}...` : versionsString

            return (
              <Step key={groupKey}>
                <CustomStepLabel
                  icon={
                    <Box display="flex" justifyContent="center">
                      <Circle color="primary" fontSize="small" />
                    </Box>
                  }
                >
                  <Box textAlign="center">
                    <StepTitle variant="body2">{firstComponent.displayName}</StepTitle>
                    <Typography variant="caption">{displayText}</Typography>
                    <br />
                    <Typography variant="caption">
                      {dayjs(dueDate).format('MMM D, YYYY')} - {_.size(components)}{' '}
                      {_.size(components) > 1 ? dic.future_timeline.component.plural : dic.future_timeline.component.singular}
                    </Typography>
                  </Box>
                </CustomStepLabel>
              </Step>
            )
          })}
        </Stepper>
      </Grid>

      {displayGroups.length > NUM_OF_EVENTS && (
        <Grid container item xs={1} alignItems="center" justifyContent="center">
          <IconButton onClick={handleNext} disabled={currentStep + NUM_OF_EVENTS >= displayGroups.length}>
            <ArrowForwardIos />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export default FutureTimeline
