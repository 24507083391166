import React from 'react'
import { AccessKeyManagement, useSession, useUser } from '@descope/react-sdk'
import _ from 'lodash'
import Loader from '../../loader'
import { Box, Grid } from '@mui/material'
import dic from '../../../dictionary'

const AccessKeys = () => {
  const { isSessionLoading } = useSession()
  const { user } = useUser()
  let userTenant = _.get(user, 'userTenants[0].tenantId')

  if (isSessionLoading) {
    return <Loader />
  }

  return (
    <Grid container direction="column">
      <Box display="flex" flexDirection="row" alignItems="center">
        <h1>{dic.settings.access_keys.title}</h1>
      </Box>
      {!userTenant && (
        <Box>
          <h4>{dic.settings.access_keys.no_tenant}</h4>
        </Box>
      )}
      {userTenant && <AccessKeyManagement widgetId="access-key-management-widget" tenant={userTenant} theme="light" />}
    </Grid>
  )
}

export default AccessKeys
