import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dic from '../../../../../dictionary'
import _ from 'lodash'
import { Box, CardContent, Grid, IconButton, Paper, Tab, Tabs, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { INTEGRATIONS, NOTIFICATION_TYPES, ROUTES } from '../../../../../consts'
import { getAxiosInstance } from '../../../../../axiosInstance'
import { ArrowBack } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'
import MongodbAtlasProjectLevelIntegrationInstructions from './projectLevel'
import MongodbAtlasOrganizationLevelIntegrationInstructions from './organizationLevel'
import { useNotification } from '../../../../commons/notification/notificationProvider'

const MongodbAtlasIntegrationInstructions = () => {
  const navigate = useNavigate()
  const { showNotification } = useNotification()
  const [tabValue, setTabValue] = useState(0)
  const [integrationName, setIntegrationName] = useState('')
  const [integrationNameError, setIntegrationNameError] = useState('')
  const [privateKey, setPrivateKey] = useState('')
  const [privateKeyError, setPrivateKeyError] = useState<any>('')
  const [publicKey, setPublicKey] = useState('')
  const [publicKeyError, setPublicKeyError] = useState<any>('')
  const [createLoading, setCreateLoading] = useState(false)

  const handleIntegrationNameChange = (event: any) => {
    setIntegrationName(event.target.value)
    if (event.target.value.length < 3) {
      setIntegrationNameError(dic.integrations.instructions.mongodb_atlas.integration_name.error)
    } else {
      setIntegrationNameError('')
    }
  }

  const handlePrivateKeyChange = (event: any) => {
    setPrivateKey(event.target.value)
    if (event.target.value.length >= 36) {
      setPrivateKeyError('')
    } else {
      setPrivateKeyError(dic.integrations.instructions.mongodb_atlas.step_keys.private_key.error)
    }
  }

  const handlePublicKeyChange = (event: any) => {
    setPublicKey(event.target.value)
    if (event.target.value.length >= 8) {
      setPublicKeyError('')
    } else {
      setPublicKeyError(dic.integrations.instructions.mongodb_atlas.step_keys.public_key.error)
    }
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const onBackIconClick = () => {
    navigate(_.replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', INTEGRATIONS.mongodbAtlas.type))
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setCreateLoading(true)
    // setRoleArnError(null)
    try {
      const response = await getAxiosInstance().post('/integration', {
        name: integrationName,
        type: INTEGRATIONS.mongodbAtlas.type,
        details: {
          publicKey,
          privateKey,
        },
      })

      if (response.status === 200) {
        navigate(_.replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', INTEGRATIONS.mongodbAtlas.type))
      } else {
        throw new Error('Failed to validate integration')
      }
    } catch (error: any) {
      setCreateLoading(false)
      showNotification(dic.integrations.instructions.mongodb_atlas.failed, NOTIFICATION_TYPES.ERROR)
    } finally {
      setCreateLoading(false)
    }
  }

  const isCreateDisabled = () => {
    return !integrationName || !!integrationNameError || !privateKey || !!privateKeyError || !publicKey || !!publicKeyError
  }

  return (
    <Grid>
      <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
        <IconButton onClick={onBackIconClick}>
          <ArrowBack />
        </IconButton>
        <h1>{dic.integrations.instructions.mongodb_atlas.title}</h1>
      </Box>

      <Grid container flexDirection="column" alignItems="start">
        <span> {dic.integrations.instructions.mongodb_atlas.integration_name.fill} </span>
        <TextField
          label={dic.integrations.instructions.mongodb_atlas.integration_name.title}
          variant="standard"
          value={integrationName}
          onChange={handleIntegrationNameChange}
          error={!!integrationNameError}
          helperText={integrationNameError}
          required
          sx={{ mb: '10px', mt: '10px', minWidth: '300px', minHeight: '72px' }}
        />
        <span> {dic.integrations.instructions.mongodb_atlas.select_level} </span>
      </Grid>

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Tabs" sx={{ pb: '20px', pt: '20px' }}>
        <Tab label={dic.integrations.instructions.mongodb_atlas.organization_level.title} />
        <Tab label={dic.integrations.instructions.mongodb_atlas.project_level.title} />
      </Tabs>

      <Paper variant="outlined">
        <CardContent>
          {tabValue === 0 && <MongodbAtlasOrganizationLevelIntegrationInstructions />}
          {tabValue === 1 && <MongodbAtlasProjectLevelIntegrationInstructions />}

          <form onSubmit={handleSubmit}>
            <Grid container flexDirection="row">
              <TextField
                label={dic.integrations.instructions.mongodb_atlas.step_keys.public_key.title}
                variant="standard"
                value={publicKey}
                onChange={handlePublicKeyChange}
                error={!!publicKeyError}
                helperText={publicKeyError}
                required
                sx={{ minWidth: '320px', minHeight: '72px', mt: '10px', mr: '20px' }}
              />

              <TextField
                label={dic.integrations.instructions.mongodb_atlas.step_keys.private_key.title}
                variant="standard"
                value={privateKey}
                onChange={handlePrivateKeyChange}
                error={!!privateKeyError}
                helperText={privateKeyError}
                required
                sx={{ minWidth: '320px', minHeight: '72px', mt: '10px' }}
              />
            </Grid>

            <Grid container flexDirection="row" justifyContent="flex-end" alignItems="flex-end" sx={{ mt: '20px' }}>
              <Button variant="text" color="primary" sx={{ mr: '20px' }} onClick={onBackIconClick}>
                {dic.integrations.instructions.mongodb_atlas.cancel}
              </Button>
              {!createLoading && (
                <Button type="submit" variant="contained" color="primary" disabled={isCreateDisabled()}>
                  {dic.integrations.instructions.mongodb_atlas.create}
                </Button>
              )}
              {createLoading && <CircularProgress color="primary" />}
            </Grid>
          </form>
        </CardContent>
      </Paper>
    </Grid>
  )
}

export default MongodbAtlasIntegrationInstructions
