import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { ContentCopy } from '@mui/icons-material'
import { CODE_BLOCK_FORMATS } from '../../../consts'

const CodeBlock = (props: any) => {
  const copyToClipboard = async () => {
    if (props.format === CODE_BLOCK_FORMATS.json) {
      await navigator.clipboard.writeText(JSON.stringify(props.code, null, 2))
    } else {
      await navigator.clipboard.writeText(props.code)
    }
  }

  const formatCode = (code: string, format: string) => {
    try {
      return format === CODE_BLOCK_FORMATS.json ? JSON.stringify(code, null, 2) : code
    } catch (e) {
      return code
    }
  }

  return (
    <Box sx={{ background: '#f0f0f0' }}>
      <Grid container flexDirection="row" alignItems="start">
        <Typography variant="body2" component="pre" sx={{ pt: '10px', pb: '10px', pl: '5px', flex: 1, whiteSpace: 'pre-wrap' }}>
          {formatCode(props.code, props.format)}
        </Typography>
        <IconButton onClick={copyToClipboard}>
          <ContentCopy />
        </IconButton>
      </Grid>
    </Box>
  )
}

export default CodeBlock
