import styled from '@emotion/styled'
import { styled as muiStyled } from '@mui/material/styles'
import { TableCell } from '@mui/material'
import { CenteredTableCell } from '../../common.css'

export const TechnologyIcon = styled.img`
  height: 32px;
`

export const IconTableCell = muiStyled(TableCell)`
  width: 120px;
`

export const CenteredIconTableCell = muiStyled(CenteredTableCell)`
  padding: 5px 0 0 0;
`
