import React from 'react'
import dic from '../../../../dictionary'
import _ from 'lodash'
import { Grid, Card } from '@mui/material'
import { WidgetTitle } from '../../../../common.css'
import IntegrationSummaryCard from './components/integrationSummaryCard'

const CLOUD_PROVIDERS_INTEGRATIONS = {
  AWS: 'aws',
  GCP: 'gcp',
  AZURE: 'azure',
}

const IntegrationsSummary = (props: any) => {
  const allComponents = props.components
  const allIntegrations = props.integrations

  const calculateIntegrationDetails = (type: any) => {
    const integrations = _.filter(allIntegrations, { type: type })
    const integrationIds = _.map(integrations, 'id')
    const integrationComponents = _.filter(allComponents, (component) => _.includes(integrationIds, component.integrationId))

    return {
      type,
      integrationCount: integrations.length || 0,
      componentsCount: integrationComponents.length || 0,
    }
  }

  const integrationsSummaryData = [
    calculateIntegrationDetails(CLOUD_PROVIDERS_INTEGRATIONS.AWS),
    calculateIntegrationDetails(CLOUD_PROVIDERS_INTEGRATIONS.GCP),
    calculateIntegrationDetails(CLOUD_PROVIDERS_INTEGRATIONS.AZURE),
  ]

  return (
    <Card variant="outlined" sx={{ p: '16px 8px' }}>
      <Grid sx={{ ml: '8px' }}>
        <WidgetTitle> {dic.dashboard.integration_summary.title}</WidgetTitle>
      </Grid>

      <Grid container flexDirection="row" justifyContent="space-between" sx={{ mt: '16px' }}>
        {_.map(integrationsSummaryData, (integration, index) => {
          return (
            <Grid item xs={4} key={index}>
              <IntegrationSummaryCard integration={integration} />
            </Grid>
          )
        })}
      </Grid>
    </Card>
  )
}

export default IntegrationsSummary
