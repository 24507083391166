import React from 'react'
import dic from '../../../../../../../dictionary'
import { Grid, Link, TextField, Typography } from '@mui/material'
import CodeBlock from '../../../../../../commons/codeBlock'
import { IntegrationQuote } from '../../../../../integrations.style'
import _ from 'lodash'
import { CODE_BLOCK_FORMATS } from '../../../../../../../consts'

const CUSTOM_TRUST_POLICY = {
  Version: '2012-10-17',
  Statement: [
    {
      Effect: 'Allow',
      Principal: {
        AWS: 'arn:aws:iam::339712924365:role/draftt-fetcher',
      },
      Action: 'sts:AssumeRole',
      Condition: {
        StringEquals: {
          'sts:ExternalId': '<YOUR_EXTERNAL_ID_PROVIDED_BY_DRAFTT>',
        },
      },
    },
  ],
}

const AwsIntegrationCloudFormationStackSetInstructions = (props: any) => {
  return (
    <Grid>
      <Grid container flexDirection="column" alignItems="start">
        <Typography variant="subtitle1">
          {dic.integrations.instructions.aws.cloud_formation_stack_set.connect}
          <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation_stack_set.main_account} </IntegrationQuote>
        </Typography>

        <Grid container flexDirection="column" alignItems="start">
          <h3>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.title}</h3>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.navigate_to}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.roles}&nbsp;</IntegrationQuote>
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.and_click_on}&nbsp;
            <Link target="blank" href="https://us-east-1.console.aws.amazon.com/iam/home?region=us-east-1#/roles/create">
              {dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.custom_trust_policy}
            </Link>
          </Typography>
          <Typography variant="subtitle1">{dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.copy}:</Typography>
          <Grid>
            <CodeBlock
              code={_.set(CUSTOM_TRUST_POLICY, 'Statement[0].Condition.StringEquals["sts:ExternalId"]', props.externalId)}
              format={CODE_BLOCK_FORMATS.json}
            />
          </Grid>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.click_on}
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.next}</IntegrationQuote>
          </Typography>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.on_the}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.add_permissions}&nbsp;</IntegrationQuote>
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.section}:
          </Typography>
          <Grid>
            <CodeBlock code={dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.permission_1} format={CODE_BLOCK_FORMATS.text} />
          </Grid>
          <Grid sx={{ mt: '8px' }}>
            <CodeBlock code={dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.permission_2} format={CODE_BLOCK_FORMATS.text} />
          </Grid>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.click_on}
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.next}</IntegrationQuote>
          </Typography>
          <Typography variant="subtitle1">{dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.enter}&nbsp;</Typography>
          <Grid>
            <CodeBlock code={dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.name} format={CODE_BLOCK_FORMATS.text} />
          </Grid>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.review}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.create_role}&nbsp;</IntegrationQuote>
          </Typography>

          <span>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_1.enter_role_arn} </span>

          <TextField
            label={dic.integrations.instructions.aws.cloud_formation_stack_set.stackset_role_arn.title}
            variant="standard"
            value={props.stackSetRoleArn}
            onChange={props.handleStackSetRoleArnChange}
            error={!!props.stackSetRoleArnError}
            helperText={props.stackSetRoleArnError}
            required
            sx={{ minWidth: '300px', minHeight: '72px', mt: '10px' }}
          />
        </Grid>

        <Grid container flexDirection="column" alignItems="start">
          <h3>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.title}</h3>
          <Grid container flexDirection="row" alignItems="center">
            <Typography variant="subtitle1">{dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.navigate_to}&nbsp;</Typography>
            <Link target="blank" href="https://us-east-1.console.aws.amazon.com/cloudformation/home/stacks/create?region=us-east-1#/stacksets">
              {dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.aws_cloud_formation}
            </Link>
          </Grid>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.click_on}
            <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.create_stackset} </IntegrationQuote>
          </Typography>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.template_section}
            <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.service_managed_permissions} </IntegrationQuote>
          </Typography>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.prerequisite}
            <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.template} </IntegrationQuote>
          </Typography>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.specify_template}
            <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.amazon_s3_url} </IntegrationQuote>
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.provide_link}
          </Typography>
          <Grid>
            <CodeBlock code={dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.draftt_cloud_formation_link}></CodeBlock>
          </Grid>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.now_press}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_2.next}</IntegrationQuote>
          </Typography>
        </Grid>

        <Grid container flexDirection="column" alignItems="start">
          <h3>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.title}</h3>
          <Typography variant="subtitle1">{dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.name_the_stack}:</Typography>
          <Grid>
            <CodeBlock code={dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.draftt_stackset} />
          </Grid>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.recommendation}
            <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.draftt_role_name} </IntegrationQuote>
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.parameter}&nbsp;
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.different_name}
          </Typography>
          <TextField
            label={dic.integrations.instructions.aws.cloud_formation_stack_set.role_name.title}
            variant="standard"
            value={props.roleName}
            onChange={props.handleRoleNameChange}
            error={!!props.roleNameError}
            helperText={props.roleNameError}
            required
            sx={{ minWidth: '300px', minHeight: '72px', mt: '10px' }}
          />
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.on_the}
            <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.external_id} </IntegrationQuote>
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.following_value}
          </Typography>
          <Grid>
            <CodeBlock code={props.externalId}></CodeBlock>
          </Grid>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.now_press}
            <IntegrationQuote> {dic.integrations.instructions.aws.cloud_formation_stack_set.step_3.next} </IntegrationQuote>
          </Typography>
        </Grid>

        <Grid>
          <h3>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_4.title}</h3>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_4.behavior}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_4.next}&nbsp;</IntegrationQuote>
          </Typography>
        </Grid>

        <Grid>
          <h3>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_5.title}</h3>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_5.on_the}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_5.deployment_options}&nbsp;</IntegrationQuote>
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_5.screen_choose}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_5.deploy_new}</IntegrationQuote>
          </Typography>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_5.recommendation}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_5.auto_deployment}</IntegrationQuote>
          </Typography>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_5.specify_regions}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_5.only_one_region}</IntegrationQuote>
          </Typography>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_5.we_recommend}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_5.next}</IntegrationQuote>
          </Typography>
        </Grid>

        <Grid>
          <h3>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_6.title}</h3>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_6.important}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_6.acknowledge}</IntegrationQuote>
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_6.statement}
          </Typography>
          <Typography variant="subtitle1">
            {dic.integrations.instructions.aws.cloud_formation_stack_set.step_6.now_press}&nbsp;
            <IntegrationQuote>{dic.integrations.instructions.aws.cloud_formation_stack_set.step_6.submit}</IntegrationQuote>
          </Typography>
        </Grid>

        <Grid sx={{ mt: '20px' }}>
          <Typography variant="subtitle1">{dic.integrations.instructions.aws.cloud_formation_stack_set.provide_stack_arn}&nbsp;</Typography>
        </Grid>
        <TextField
          label={dic.integrations.instructions.aws.cloud_formation_stack_set.stackset_arn.title}
          variant="standard"
          value={props.stackSetArn}
          onChange={props.handleStackSetArnChange}
          error={!!props.stackSetArnError}
          helperText={props.stackSetArnError}
          required
          sx={{ minWidth: '300px', minHeight: '72px', mt: '10px' }}
        />
      </Grid>
    </Grid>
  )
}

export default AwsIntegrationCloudFormationStackSetInstructions
