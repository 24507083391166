import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dic from '../../../../../dictionary'
import _ from 'lodash'
import { Autocomplete, Box, Card, CardContent, Grid, IconButton, Link, Paper, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Loader from '../../../../loader'
import { INTEGRATIONS, NOTIFICATION_TYPES, ROUTES } from '../../../../../consts'
import { getAxiosInstance } from '../../../../../axiosInstance'
import { ArrowBack } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'
import { IntegrationQuote } from '../../../integrations.style'
import CodeBlock from '../../../../commons/codeBlock'
import { useNotification } from '../../../../commons/notification/notificationProvider'

const K8S_EXPLORER_DEFAULT_STATUS = 'pending'
const COMPONENT_TECHNOLOGY_FILTER = 'k8s'

const K8sExplorerIntegrationInstructions = () => {
  const navigate = useNavigate()
  const { showNotification } = useNotification()
  const [clusterName, setClusterName] = useState('')
  const [clusterNameError, setClusterNameError] = useState('')
  const [k8sClusters, setK8sClusters] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [createLoading, setCreateLoading] = useState(false)
  // const [createError, setCreateError] = useState<any>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allK8sClusters = await getAxiosInstance().get(`/component`, {
          params: {
            technology: COMPONENT_TECHNOLOGY_FILTER,
          },
        })

        const activeK8sIntegrations = await getAxiosInstance().get('/integration', {
          params: {
            type: INTEGRATIONS.k8sExplorer.type,
          },
        })

        const k8sClustersWithoutExplorerIntegration = _.filter(allK8sClusters.data, (cluster: any) => {
          return !_.find(activeK8sIntegrations.data, (activeIntegration) => {
            return _.get(activeIntegration, 'details.clusterId') === cluster.uniqueIdentifier
          })
        })

        setK8sClusters(_.map(k8sClustersWithoutExplorerIntegration, 'uniqueIdentifier'))
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  if (loading) {
    return <Loader />
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setCreateLoading(true)
    setClusterNameError('')
    try {
      const response = await getAxiosInstance().post('/integration', {
        name: clusterName,
        type: INTEGRATIONS.k8sExplorer.type,
        details: {
          clusterId: clusterName,
          status: K8S_EXPLORER_DEFAULT_STATUS,
        },
      })

      if (response.status === 200) {
        navigate(_.replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', INTEGRATIONS.k8sExplorer.type))
      } else {
        throw new Error(`Integration validation failed`)
      }
    } catch (error: any) {
      setCreateLoading(false)
      showNotification(dic.integrations.instructions.k8s_explorer.failed, NOTIFICATION_TYPES.ERROR)
    } finally {
      setCreateLoading(false)
    }
  }

  const onBackIconClick = () => {
    navigate(_.replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', INTEGRATIONS.k8sExplorer.type))
  }

  const handleClusterChange = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setClusterName(newValue)
    validateClusterName()
  }

  const validateClusterName = () => {
    if (clusterName === null) {
      setClusterNameError(dic.integrations.instructions.k8s_explorer.step_1.select_cluster_error)
    } else {
      setClusterNameError('')
    }
  }

  return (
    <Grid>
      <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
        <IconButton onClick={onBackIconClick}>
          <ArrowBack />
        </IconButton>
        <h1>{dic.integrations.instructions.k8s_explorer.title}</h1>
      </Box>

      <form onSubmit={handleSubmit}>
        <Card variant="outlined" sx={{ borderRadius: '15px' }}>
          <CardContent>
            <Grid container flexDirection="column" alignItems="start">
              <Typography variant="subtitle1">{dic.integrations.instructions.k8s_explorer.in_order}</Typography>
            </Grid>

            <Grid container flexDirection="column" alignItems="start">
              <h3>{dic.integrations.instructions.k8s_explorer.step_1.title}</h3>
              <Autocomplete
                options={k8sClusters}
                value={clusterName}
                onChange={handleClusterChange}
                onBlur={validateClusterName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={dic.integrations.instructions.k8s_explorer.step_1.select_cluster}
                    variant="standard"
                    error={!!clusterNameError}
                    helperText={clusterNameError}
                  />
                )}
                PaperComponent={(props) => <Paper {...props} style={{ width: 500 }} />}
                sx={{ mb: '10px', minWidth: '500px', minHeight: '72px' }}
              />
            </Grid>

            <Grid container flexDirection="column" alignItems="start">
              <h3>{dic.integrations.instructions.k8s_explorer.step_2.title} </h3>
              <Typography variant="subtitle1">{dic.integrations.instructions.k8s_explorer.step_2.helm_install}</Typography>
              <Typography variant="subtitle1">{dic.integrations.instructions.k8s_explorer.step_2.add_helm_repo}</Typography>
              <Grid>
                <CodeBlock code={dic.integrations.instructions.k8s_explorer.step_2.helm_install_instructions_add}></CodeBlock>
              </Grid>
              <Typography variant="subtitle1"> {dic.integrations.instructions.k8s_explorer.step_2.helm_install_instructions_update}</Typography>
              <Grid>
                <CodeBlock code={dic.integrations.instructions.k8s_explorer.step_2.helm_install_instructions_update_command}></CodeBlock>
              </Grid>
            </Grid>

            <Grid container flexDirection="column" alignItems="start">
              <h3>{dic.integrations.instructions.k8s_explorer.step_3.title} </h3>
              <Typography variant="subtitle1">
                {dic.integrations.instructions.k8s_explorer.step_3.navigate_to}
                <Link target="_blank" href={`${ROUTES.SETTINGS.ACCESS_KEYS}`}>
                  {dic.integrations.instructions.k8s_explorer.step_3.access_keys}
                </Link>
              </Typography>
              <Typography variant="subtitle1">
                {dic.integrations.instructions.k8s_explorer.step_3.create}
                <IntegrationQuote>{dic.integrations.instructions.k8s_explorer.step_3.save}</IntegrationQuote>
              </Typography>
            </Grid>

            <Grid container flexDirection="column" alignItems="start">
              <h3>{dic.integrations.instructions.k8s_explorer.step_4.title} </h3>
              <Typography variant="subtitle1"> {dic.integrations.instructions.k8s_explorer.step_4.collector}</Typography>
              <Typography variant="subtitle1"> {dic.integrations.instructions.k8s_explorer.step_4.create_secret}</Typography>
              <Typography variant="subtitle1">
                <IntegrationQuote>{dic.integrations.instructions.k8s_explorer.step_4.right_config}</IntegrationQuote>
              </Typography>
              <Grid>
                <CodeBlock code={dic.integrations.instructions.k8s_explorer.step_4.secret_code}></CodeBlock>
              </Grid>
            </Grid>

            <Grid container flexDirection="column" alignItems="start">
              <h3>{dic.integrations.instructions.k8s_explorer.step_5.title} </h3>
              <Typography variant="subtitle1"> {dic.integrations.instructions.k8s_explorer.step_5.install_chart}</Typography>
              <Grid>
                <CodeBlock
                  code={`${dic.integrations.instructions.k8s_explorer.step_5.helm_install_chart_prefix}${clusterName ? clusterName : dic.integrations.instructions.k8s_explorer.step_5.helm_install_chart_cluster_name_placeholder}${dic.integrations.instructions.k8s_explorer.step_5.helm_install_chart_suffix}`}
                ></CodeBlock>
              </Grid>
            </Grid>

            <Grid container flexDirection="column" alignItems="start">
              <h3>{dic.integrations.instructions.k8s_explorer.step_6.title} </h3>
              <Typography variant="subtitle1"> {dic.integrations.instructions.k8s_explorer.step_6.run}</Typography>
              <Grid>
                <CodeBlock code={`${dic.integrations.instructions.k8s_explorer.step_6.command}`}></CodeBlock>
              </Grid>
            </Grid>

            <Grid container flexDirection="row" justifyContent="flex-end" alignItems="flex-end" sx={{ mt: '20px' }}>
              <Button variant="text" color="primary" sx={{ mr: '20px' }} onClick={onBackIconClick}>
                {dic.integrations.instructions.k8s_explorer.cancel}
              </Button>
              {!createLoading && (
                <Button type="submit" variant="contained" color="primary" disabled={loading || !clusterName || !!clusterNameError}>
                  {dic.integrations.instructions.k8s_explorer.create}
                </Button>
              )}
              {createLoading && <CircularProgress color="primary" />}
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Grid>
  )
}

export default K8sExplorerIntegrationInstructions
