import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dic from '../../../dictionary'
import { ROUTES } from '../../../consts'
import _ from 'lodash'
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { StyledTableHead } from '../../policies/policies.style'
import { ClickableTableRow } from '../../../common.css'

const DrafttsTable = (props: any) => {
  const draftts = props.draftts
  const navigate = useNavigate()

  const onRowClick = (draftt: any) => {
    navigate(_.replace(ROUTES.DRAFTTS.DETAILS, ':drafttId', _.get(draftt, 'id')), { state: { draftt } })
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <StyledTableHead>
          <TableRow>
            <TableCell>{dic.draftts.table.technology}</TableCell>
            <TableCell>{dic.draftts.table.name}</TableCell>
            <TableCell>{dic.draftts.table.integration_name}</TableCell>
            <TableCell>{dic.draftts.table.current_version}</TableCell>
            <TableCell>{dic.draftts.table.desired_version}</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {draftts.length > 0 &&
            _.map(draftts, (draftt) => (
              <ClickableTableRow key={_.get(draftt, 'name')} onClick={() => onRowClick(draftt)}>
                <TableCell>{_.get(draftt, 'displayName')}</TableCell>
                <TableCell>{_.get(draftt, 'name')}</TableCell>
                <TableCell>{_.get(draftt, 'integrationName')}</TableCell>
                <TableCell>{_.get(draftt, 'currentVersion')}</TableCell>
                <TableCell>{_.get(draftt, 'desiredVersion')}</TableCell>
              </ClickableTableRow>
            ))}

          {draftts.length === 0 && (
            <TableRow>
              <TableCell colSpan={7} sx={{ textAlign: 'center', verticalAlign: 'middle' }}>
                {dic.draftts.table.no_draftts}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DrafttsTable
