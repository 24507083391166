import React, { useMemo, useState, useEffect } from 'react'
import dic from '../../../../dictionary'
import _ from 'lodash'
import { Grid } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { GridColDef, GridColumnVisibilityModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { DataGridPro } from '@mui/x-data-grid-pro'
import ComponentTags from '../../../commons/componentTags'
import { TAGS_DELIMITER, DATAGRID_PROPS } from '../../../../consts'

const CompliantTable = (props: any) => {
  const compliantPolicyComponents = props.compliantPolicyComponents
  const sortedCompliantPolicyComponents = _.filter(compliantPolicyComponents, { isCompliant: true })

  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 })
  const [tableHeight, setTableHeight] = useState(DATAGRID_PROPS.BASE_HEIGHT + paginationModel.pageSize * DATAGRID_PROPS.ROW_HEIGHT)

  const columns: GridColDef[] = useMemo(() => {
    return [
      { field: 'technology', headerName: dic.policy_tabs.compliant_table.technology, flex: 2 },
      { field: 'name', headerName: dic.policy_tabs.compliant_table.name, flex: 4 },
      { field: 'currentVersion', headerName: dic.policy_tabs.compliant_table.current_version, flex: 2 },
      ...props.tagsFilters,
    ]
  }, [props.tagsFilters])

  const rows = useMemo(() => {
    return _.map(compliantPolicyComponents, (component) => ({
      id: _.get(component, 'id'),
      technology: _.get(component, 'displayName'),
      name: _.get(component, 'name'),
      currentVersion: _.get(component, 'currentVersion'),
      tags: _.get(component, 'tags'),
      integrationName: _.get(component, 'integrationName'),
      updatedAt: _.get(component, 'updated_at'),
      ..._.reduce(
        _.get(component, 'tags', []),
        (acc: any, tag) => {
          acc[`${TAGS_DELIMITER}${_.get(tag, 'key')}`] = _.get(tag, 'value')
          return acc
        },
        {},
      ),
    }))
  }, [compliantPolicyComponents])

  const initialVisibilityModel: GridColumnVisibilityModel = useMemo(() => {
    return _.reduce(
      props.tagsFilters,
      (model: GridColumnVisibilityModel, col) => {
        model[col.field] = false
        return model
      },
      {} as GridColumnVisibilityModel,
    )
  }, [props.tagsFilters])

  const getDetailPanelContent = (params: any) => {
    return (
      <Grid container margin={2} spacing={1} flexDirection="column" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <strong>{dic.policy_tabs.compliant_table.collapsable_data.integration_name}: </strong>
          <span>{_.get(params, 'row.integrationName')}</span>
        </Grid>
        <Grid item>
          <strong>{dic.policy_tabs.compliant_table.collapsable_data.updated_at}:</strong>
          <span> {dayjs(_.get(params, 'row.updated_at')).format('MMM D, YYYY')}</span>
        </Grid>
        {_.get(params, 'row.tags', []).length > 0 && <ComponentTags tags={_.get(params, 'row.tags')} />}
      </Grid>
    )
  }

  useEffect(() => {
    const height =
      paginationModel.pageSize > sortedCompliantPolicyComponents.length
        ? DATAGRID_PROPS.BASE_HEIGHT + sortedCompliantPolicyComponents.length * DATAGRID_PROPS.ROW_HEIGHT
        : DATAGRID_PROPS.BASE_HEIGHT + paginationModel.pageSize * DATAGRID_PROPS.ROW_HEIGHT
    setTableHeight(height)
  }, [paginationModel, sortedCompliantPolicyComponents.length])

  return (
    <Grid item xs={12} sx={{ height: tableHeight }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick={true}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 'auto'}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 25, 50]}
        slots={{
          detailPanelExpandIcon: KeyboardArrowDown,
          detailPanelCollapseIcon: KeyboardArrowUp,
          toolbar: () => (
            <GridToolbarContainer sx={{ pt: '8px' }}>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
            </GridToolbarContainer>
          ),
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
          columns: {
            columnVisibilityModel: initialVisibilityModel,
          },
        }}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'white',
          },
        }}
      />
    </Grid>
  )
}

export default CompliantTable
