import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dic from '../../../dictionary'
import { INTEGRATIONS, ROUTES } from '../../../consts'
import { getAxiosInstance } from '../../../axiosInstance'
import Loader from '../../loader'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { StyledTableHead } from '../../policies/policies.style'
import { TechnologyIcon } from '../../catalog/catalog.style'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import dayjs from 'dayjs'
import Button from '@mui/material/Button'

const IntegrationsByType = () => {
  const { type } = useParams<{ type: string }>()
  const navigate = useNavigate()
  const [integrationsData, setIntegrationsData] = useState([])
  const [integrationDisplayName, setIntegrationDisplayName] = useState('')
  const [integrationProps, setIntegrationProps] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const integrationDetails = _.find(INTEGRATIONS, { type })
    if (integrationDetails) {
      setIntegrationDisplayName(integrationDetails.title)
    }

    const fetchData = async () => {
      try {
        if (type) {
          const integrations = await getAxiosInstance().get(`/integration`, {
            params: {
              type,
            },
          })

          if (_.get(integrations, 'data.[0]')) {
            setIntegrationProps(_.uniq(_.flatMap(integrations.data, (item) => _.keys(item.details))))
          }
          setIntegrationsData(integrations.data)

          setLoading(false)
        } else {
          navigate(ROUTES.INTEGRATIONS.BY_TYPE)
        }
      } catch (error) {
        setLoading(false)
        // setError('Error fetching data');
      }
    }
    fetchData()
  }, [])

  if (loading) {
    return <Loader />
  }

  const onBackIconClick = () => {
    navigate(ROUTES.INTEGRATIONS.ALL)
  }

  const onCreateClick = (type: string | undefined) => {
    if (type) {
      navigate(_.replace(ROUTES.INTEGRATIONS.CREATE, ':type', type))
    }
  }

  return (
    <Grid>
      <Grid container flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
          <IconButton onClick={onBackIconClick}>
            <ArrowBack />
          </IconButton>
          <TechnologyIcon src={`https://draftt-public.s3.amazonaws.com/icons/${type}.svg`} alt={type} />
          <h1>
            {integrationDisplayName} {dic.integrations.by_type.title} ({integrationsData.length})
          </h1>
        </Box>
        <Button variant="contained" endIcon={<ArrowForward />} onClick={() => onCreateClick(type)}>
          {dic.integrations.by_type.create_integration}
        </Button>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>{dic.integrations.by_type.table.name}</TableCell>
              {_.map(integrationProps, (integrationProp) => {
                return <TableCell key={integrationProp}>{_.startCase(integrationProp)}</TableCell>
              })}
              <TableCell>{dic.integrations.by_type.table.created_at}</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {integrationsData.length > 0 &&
              _.map(integrationsData, (integration) => (
                <TableRow key={_.get(integration, 'name')}>
                  <TableCell>{_.get(integration, 'name')}</TableCell>
                  {_.map(integrationProps, (integrationProp) => {
                    return <TableCell key={integrationProp}>{_.get(integration, `details.${integrationProp}`)}</TableCell>
                  })}
                  <TableCell>{dayjs(_.get(integration, 'createdAt')).format('MMM D, YYYY')}</TableCell>
                </TableRow>
              ))}

            {integrationsData.length === 0 && (
              <TableRow>
                <TableCell colSpan={2} sx={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {dic.integrations.by_type.table.no_active_integrations}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default IntegrationsByType
