import React from 'react'
import dic from '../../../../../../dictionary'
import { Paper, Grid, Typography } from '@mui/material'
import { ArrowForward, ErrorOutline, WarningAmber } from '@mui/icons-material'
import Button from '@mui/material/Button'
import { NOTIFICATION_TYPES } from '../../../../../../consts'
import { useNavigate } from 'react-router-dom'

const InsightCard = (props: any) => {
  const navigate = useNavigate()

  return (
    <Paper variant="outlined" sx={{ m: '5px 0', height: '40px' }}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={11} container alignItems="center">
          {props.type === NOTIFICATION_TYPES.ERROR && <ErrorOutline fontSize="small" color="error" sx={{ m: '0 10px' }} />}
          {props.type === NOTIFICATION_TYPES.WARNING && <WarningAmber fontSize="small" color="warning" sx={{ m: '0 10px' }} />}
          <Typography variant="body2">{props.message}</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <Button endIcon={<ArrowForward />} sx={{ textTransform: 'none', mr: '10px' }} onClick={() => navigate(props.link)}>
            {dic.dashboard.insights.card.view}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default InsightCard
