import { styled as muiStyled } from '@mui/material/styles'
import { Avatar } from '@mui/material'

export const MetricIcon = muiStyled(Avatar)`
  height: 36px;
  width:36px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: black;
  
  img {
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`
