import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Alert, Grid, Link } from '@mui/material'
import dic from '../../../../../dictionary'
import { DRAFTT_STATUSES } from '../../../../../consts'

const LINK_SEPARATOR = 'here#'

const RequiredChange = (props: any) => {
  const [descriptionText, setDescriptionText] = useState('')
  const [descriptionLink, setDescriptionLink] = useState('')
  const [alertStatus, setAlertStatus] = useState<'info' | 'warning'>('warning')

  useEffect(() => {
    if (props.description) {
      const [text, link] = _.split(props.description, LINK_SEPARATOR, 2)
      setDescriptionText(text)
      setDescriptionLink(_.trim(link))
    }

    if (props.status === DRAFTT_STATUSES.CHANGE.INFO) {
      setAlertStatus('info')
    }
  }, [props.description])

  return (
    <Grid container>
      {descriptionText && (
        <Grid item xs={12}>
          <Alert severity={alertStatus} icon={false}>
            {descriptionText}
            {descriptionLink && (
              <Link href={descriptionLink} target="_blank">
                {dic.draftt.change_alert.link_text}
              </Link>
            )}
          </Alert>
        </Grid>
      )}
    </Grid>
  )
}

export default RequiredChange
