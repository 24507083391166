import React from 'react'
import dic from '../../../../../../dictionary'
import { Grid, Link, Typography } from '@mui/material'
import CodeBlock from '../../../../../commons/codeBlock'
import { IntegrationQuote } from '../../../../integrations.style'
import { CODE_BLOCK_FORMATS } from '../../../../../../consts'

const MongodbAtlasProjectLevelIntegrationInstructions = () => {
  return (
    <Grid>
      <Grid container flexDirection="column" alignItems="start">
        <Typography variant="subtitle1"> {dic.integrations.instructions.mongodb_atlas.project_level.connect} </Typography>
        <h3>{dic.integrations.instructions.mongodb_atlas.project_level.step_1.title}</h3>
        <Grid container flexDirection="row" alignItems="center">
          <Typography variant="subtitle1">{dic.integrations.instructions.mongodb_atlas.project_level.step_1.navigate_to}&nbsp;</Typography>
          <Link target="blank" href="https://cloud.mongodb.com/v2#/preferences/organizations">
            {dic.integrations.instructions.mongodb_atlas.project_level.step_1.organizations_page}
          </Link>
        </Grid>
        <Typography variant="subtitle1">{dic.integrations.instructions.mongodb_atlas.project_level.step_1.select} </Typography>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.mongodb_atlas.project_level.step_1.now_select}&nbsp;
          <IntegrationQuote>{dic.integrations.instructions.mongodb_atlas.project_level.step_1.project_settings}</IntegrationQuote>
        </Typography>
      </Grid>

      <Grid container flexDirection="column" alignItems="start">
        <h3>{dic.integrations.instructions.mongodb_atlas.project_level.step_2.title}</h3>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.mongodb_atlas.project_level.step_2.click_on}&nbsp;
          <IntegrationQuote>{dic.integrations.instructions.mongodb_atlas.project_level.step_2.access_manager}</IntegrationQuote>
        </Typography>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.mongodb_atlas.project_level.step_2.now_press}&nbsp;
          <IntegrationQuote>{dic.integrations.instructions.mongodb_atlas.project_level.step_2.api_keys}</IntegrationQuote>
        </Typography>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.mongodb_atlas.project_level.step_2.in_the}&nbsp;
          <IntegrationQuote>{dic.integrations.instructions.mongodb_atlas.project_level.step_2.description}</IntegrationQuote>
          &nbsp;{dic.integrations.instructions.mongodb_atlas.project_level.step_2.enter_the_following}
        </Typography>
        <Grid>
          <CodeBlock code={dic.integrations.instructions.mongodb_atlas.project_level.step_2.draftt_api_key} format={CODE_BLOCK_FORMATS.text} />
        </Grid>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.mongodb_atlas.project_level.step_2.in_the}&nbsp;
          <IntegrationQuote>{dic.integrations.instructions.mongodb_atlas.project_level.step_2.permissions}</IntegrationQuote>
          &nbsp;{dic.integrations.instructions.mongodb_atlas.project_level.step_2.select_the_following}&nbsp;
          <IntegrationQuote>{dic.integrations.instructions.mongodb_atlas.project_level.step_2.read_only}</IntegrationQuote>
        </Typography>
        <Typography variant="subtitle1">
          {dic.integrations.instructions.mongodb_atlas.project_level.step_2.click_on}&nbsp;
          <IntegrationQuote>{dic.integrations.instructions.mongodb_atlas.project_level.step_2.next}</IntegrationQuote>
          &nbsp;{dic.integrations.instructions.mongodb_atlas.project_level.step_2.and}&nbsp;
          {dic.integrations.instructions.mongodb_atlas.step_keys.enter_keys.title}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default MongodbAtlasProjectLevelIntegrationInstructions
