import { styled as muiStyled } from '@mui/material/styles'
import styled from '@emotion/styled'
import { Card } from '@mui/material'

export const IntegrationCard = muiStyled(Card)`
  width: 330px;
  max-height: 250px;
  border-radius: 15px;
  margin-right: 40px;
  margin-bottom: 40px;
`

export const IntegrationIcon = styled.img`
  height: 50px;
  width: 50px;
`

export const IntegrationQuote = styled.span`
  font-weight: 600;
`

export const IntegrationDescription = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
