import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dic from '../../dictionary'
import Button from '@mui/material/Button'
import { Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { PolicyStatusCompliant, PolicyStatusHeader, PolicyStatusViolations, StyledTableHead } from './policies.style'
import { ArrowForward } from '@mui/icons-material'
import { ROUTES } from '../../consts'
import { getAxiosInstance } from '../../axiosInstance'
import Loader from '../loader'
import _ from 'lodash'
import { ChartTableCell } from '../../common.css'

const Policies = () => {
  const navigate = useNavigate()
  const [policies, setPolicies] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allPolicies = await getAxiosInstance().get('/policy')
        const allPoliciesComponents = await getAxiosInstance().get('/policyComponent')
        const policiesWithStatus = calculatePolicyStatus(allPolicies.data, allPoliciesComponents.data)
        setPolicies(policiesWithStatus)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        // setError('Error fetching data');
      }
    }
    fetchData()
  }, [])

  const calculatePolicyStatus = (policies: any[], policyComponents: any[]) => {
    return _.map(policies, (policy: any) => {
      const policyComponentsForPolicy = _.filter(policyComponents, { policyId: policy.id })

      const compliant = _.size(
        _.filter(policyComponentsForPolicy, (policyComponent: any) => {
          return policyComponent.isCompliant === true
        }),
      )

      const violations = policyComponentsForPolicy.length - compliant
      return {
        ...policy,
        status: {
          compliant,
          violations,
        },
      }
    })
  }

  const onEnableToggleClick = (policy: any) => {
    //TODO: disable policy in the DB
    policy.isEnabled = !policy.isEnabled
  }

  const onReviewClick = (policy: any) => {
    navigate(_.replace(ROUTES.POLICIES.SINGLE, ':policyId', policy.id))
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Grid>
      <h1> {dic.policies_title} </h1>
      <h3> {dic.suggested_policies_title} </h3>
      <TableContainer component={Paper}>
        <Table>
          <StyledTableHead>
            <TableRow>
              {/*<TableCell>{dic.active}</TableCell>*/}
              <TableCell>{dic.policy_name}</TableCell>
              <TableCell>{dic.description}</TableCell>
              <TableCell>{dic.affected_teams}</TableCell>
              <TableCell>{dic.policy_summary}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {policies.map((policy) => (
              <TableRow key={_.get(policy, 'name')}>
                {/*<TableCell>*/}
                {/*  <Switch checked={_.get(policy, 'isActive')} onChange={() => onEnableToggleClick(policy)} />*/}
                {/*</TableCell>*/}
                <TableCell>{_.get(policy, 'name')}</TableCell>
                <TableCell>{_.get(policy, 'description')}</TableCell>
                <TableCell>{dic.all_teams}</TableCell>
                <ChartTableCell>
                  <Grid container>
                    <Grid>
                      <PolicyStatusHeader>{dic.compliant}</PolicyStatusHeader>
                      <PolicyStatusCompliant>{_.get(policy, 'status.compliant')}</PolicyStatusCompliant>
                    </Grid>
                    <Grid>
                      <PolicyStatusHeader>{dic.violations}</PolicyStatusHeader>
                      <PolicyStatusViolations>{_.get(policy, 'status.violations')}</PolicyStatusViolations>
                    </Grid>
                  </Grid>
                </ChartTableCell>
                <TableCell align="right">
                  <Button variant="contained" endIcon={<ArrowForward />} onClick={() => onReviewClick(policy)}>
                    {dic.review_policy}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default Policies
