import styled from '@emotion/styled'

export const ChangeTitle = styled.h5`
  font-size: 16px;
  margin: 0;
  display: flex;
  align-items: center;
`

export const ChangeDescription = styled.span`
  font-size: 16px;
  margin: 0;
  color: #697077;
`

export const DeprecatedChangeDescription = styled.span`
  color: #697077;
`

export const HelmIcon = styled.img`
  height: 24px;
  margin-left: 5px;
`
