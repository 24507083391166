import React from 'react'
import dic from '../../../../../../dictionary'
import _ from 'lodash'
import { Grid, Card, Typography } from '@mui/material'
import { IntegrationIcon } from './integrationSummaryCard.style'

const IntegrationSummaryCard = (props: any) => {
  return (
    <Card variant="outlined" sx={{ p: '16px', margin: '0 8px' }}>
      <Grid container flexDirection="row" justifyContent="center" alignItems="center">
        <Grid item justifyContent="center" alignItems="center" sx={{ pr: '16px' }}>
          <IntegrationIcon
            src={`https://draftt-public.s3.amazonaws.com/icons/${props.integration.type}.svg`}
            alt={_.get(props.integration, 'type')}
          />
        </Grid>
        <Grid item alignItems="center">
          <Grid flexDirection="row">
            <Typography variant="subtitle2" gutterBottom={false} sx={{ display: 'inline' }}>
              {props.integration.integrationCount}
            </Typography>
            <Typography variant="body2" sx={{ display: 'inline' }}>
              {dic.dashboard.integration_summary.card.integrations}
            </Typography>
          </Grid>

          <Grid flexDirection="row">
            <Typography variant="subtitle2" sx={{ display: 'inline' }}>
              {props.integration.componentsCount}
            </Typography>
            <Typography variant="body2" sx={{ display: 'inline' }}>
              {dic.dashboard.integration_summary.card.components}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default IntegrationSummaryCard
