import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import dic from '../../../dictionary'
import { DRAFTT_STATUSES, ROUTES } from '../../../consts'
import { getAxiosInstance } from '../../../axiosInstance'
import Loader from '../../loader'
import _ from 'lodash'
import { Box, Card, CardContent, Grid, IconButton } from '@mui/material'
import { ArrowBack, CheckCircleOutlined, CircleOutlined } from '@mui/icons-material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import RequiredChange from './requiredChange'

const DrafttDetails = () => {
  const location = useLocation()
  const [draftt, setDraftt] = useState(location.state?.draftt)
  const { drafttId } = useParams<{ drafttId: string }>()
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = useState(null)
  const [drafttData, setDrafttData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const drafttDetails = await getAxiosInstance().get(`/draftt/${drafttId}`)
        if (_.get(drafttDetails, 'data.length') === 0) {
          navigate(ROUTES.DRAFTTS.ALL)
        }

        setDraftt(_.get(drafttDetails, 'data[0]'))
        updateDrafttData(_.get(drafttDetails, 'data[0].details', []))
        setLoading(false)
      } catch (error) {
        setLoading(false)
        navigate(ROUTES.DRAFTTS.ALL)
      }
    }
    fetchData()
  }, [])

  const updateDrafttData = (drafttData: any) => {
    let result

    result = _.map(drafttData, (drafttSection: any) => {
      let isCompleted = false
      if (_.get(drafttSection, 'changes', []).length === 0) {
        isCompleted = true
      } else {
        isCompleted = _.every(_.get(drafttSection, 'changes'), (change: any) => {
          return change.status === DRAFTT_STATUSES.CHANGE.ACKNOWLEDGED || change.status === DRAFTT_STATUSES.CHANGE.COMPLETED
        })
      }

      return {
        ...drafttSection,
        isCompleted,
      }
    })

    setDrafttData(result)

    if (result.length > 0) {
      let currentActiveSection = _.find(result, { title: _.get(selectedItem, 'title') })
      if (!currentActiveSection || currentActiveSection.isCompleted === true) {
        let firstActiveSection = _.find(result, (section) => section.isCompleted === false)
        setSelectedItem(firstActiveSection || result[0])
      }
    }

    return result
  }

  if (loading) {
    return <Loader />
  }

  const onBackIconClick = () => {
    navigate(ROUTES.DRAFTTS.ALL)
  }

  const onConfirmChangeStatus = async (changeId: string) => {
    await getAxiosInstance().put(`/draftt/${drafttId}/change/${changeId}`, {
      status: DRAFTT_STATUSES.CHANGE.ACKNOWLEDGED,
    })

    updateDrafttData(drafttData)
  }

  return (
    <Grid>
      <Grid container direction="column" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
          <IconButton onClick={onBackIconClick}>
            <ArrowBack />
          </IconButton>
          <h1>
            {dic.draftt.title} / {_.get(draftt, 'name')} / {_.get(draftt, 'currentVersion')} {'=>'} {_.get(draftt, 'desiredVersion')}
          </h1>
        </Box>
      </Grid>

      <Grid container flexDirection="row" gap={2}>
        <Grid item xs={3}>
          <Card variant="outlined">
            {_.map(drafttData, (drafttSection) => (
              <List key={_.get(drafttSection, 'title')} dense={true} sx={{ p: 0 }}>
                <ListItem sx={{ p: 0 }}>
                  <ListItemButton divider={true} selected={selectedItem === drafttSection} onClick={() => setSelectedItem(drafttSection)}>
                    <ListItemIcon>
                      {_.get(drafttSection, 'isCompleted') === false && <CircleOutlined />}
                      {_.get(drafttSection, 'isCompleted') === true && <CheckCircleOutlined />}
                    </ListItemIcon>
                    <ListItemText primary={_.get(drafttSection, 'title')} />
                  </ListItemButton>
                </ListItem>
              </List>
            ))}
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card variant="outlined">
            <CardContent sx={{ pt: '0px' }}>
              <Grid container flexDirection="column">
                <Grid container flexDirection="column" sx={{ mb: '20px' }}>
                  <h3>{_.get(selectedItem, 'title')}</h3>
                  <span>{_.get(selectedItem, 'description')}</span>
                </Grid>
                {selectedItem && _.get(selectedItem, 'changes', []).length === 0 && <strong>{dic.draftt.no_required_changes}</strong>}
                {selectedItem &&
                  _.get(selectedItem, 'changes', []).length > 0 &&
                  _.map(_.get(selectedItem, 'changes'), (change) => (
                    <RequiredChange key={_.get(change, 'name')} requiredChange={change} onConfirmChangeStatus={onConfirmChangeStatus} />
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DrafttDetails
