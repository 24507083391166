import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dic from '../../../../../dictionary'
import _ from 'lodash'
import { Box, Card, CardContent, Grid, IconButton, Tab, Tabs, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Loader from '../../../../loader'
import { INTEGRATIONS, NOTIFICATION_TYPES, ROUTES } from '../../../../../consts'
import { getAxiosInstance } from '../../../../../axiosInstance'
import AwsIntegrationCloudFormationInstructions from './cloudFormation/singleAccount'
import { ArrowBack, Groups, Person } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'
import AwsIntegrationManualInstallationInstructions from './manualInstallation'
import AwsIntegrationCloudFormationStackSetInstructions from './cloudFormation/stackSet'
import { useNotification } from '../../../../commons/notification/notificationProvider'

const STACKSET_PARAMS = {
  INTEGRATION_NAME: 'AWS StackSet Integration',
  TYPE: 'StackSet',
}
const AwsIntegrationInstructions = () => {
  const navigate = useNavigate()
  const { showNotification } = useNotification()
  const [tabValue, setTabValue] = useState(0)
  const [externalId, setExternalId] = useState('')
  const [integrationName, setIntegrationName] = useState('')
  const [integrationNameError, setIntegrationNameError] = useState('')
  const [roleArn, setRoleArn] = useState('')
  const [roleArnError, setRoleArnError] = useState<any>('')

  const [stackSetArn, setStackSetArn] = useState('')
  const [stackSetArnError, setStackSetArnError] = useState<any>('')
  const [stackSetRoleArn, setStackSetRoleArn] = useState('')
  const [stackSetRoleArnError, setStackSetRoleArnError] = useState<any>('')
  const [roleName, setRoleName] = useState(dic.integrations.instructions.aws.cloud_formation_stack_set.role_name.default_role_name)
  const [roleNameError, setRoleNameError] = useState<any>('')

  const [loading, setLoading] = useState(true)
  const [createLoading, setCreateLoading] = useState(false)
  // const [createError, setCreateError] = useState<any>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organization = await getAxiosInstance().get(`/organization`)
        setExternalId(_.get(organization, 'data[0].awsExternalId'))
        setLoading(false)
      } catch (error) {
        setLoading(false)
        // setError('Error fetching data');
      }
    }
    fetchData()
  }, [])

  if (loading) {
    return <Loader />
  }

  const validateIntegrationName = () => {
    if (integrationName.length < 3) {
      setIntegrationNameError('Integration name must be at least 3 characters long.')
      return false
    } else {
      setIntegrationNameError('')
      return true
    }
  }

  const handleIntegrationNameChange = (event: any) => {
    setIntegrationName(event.target.value)
    if (integrationNameError) {
      validateIntegrationName()
    }
  }

  const handleRoleArnChange = (event: any) => {
    setRoleArn(event.target.value)
    if (roleArnError) {
      setRoleArnError('')
    }
  }

  const handleStackSetArnChange = (event: any) => {
    setStackSetArn(event.target.value)
    if (event.target.value.length >= 36) {
      setStackSetArnError('')
    } else {
      setStackSetArnError(dic.integrations.instructions.aws.cloud_formation_stack_set.stackset_arn.invalid)
    }
  }

  const handleStackSetRoleArnChange = (event: any) => {
    setStackSetRoleArn(event.target.value)
    if (event.target.value.length >= 36) {
      setStackSetRoleArnError('')
    } else {
      setStackSetRoleArnError(dic.integrations.instructions.aws.cloud_formation_stack_set.stackset_role_arn.invalid)
    }
  }

  const handleRoleNameChange = (event: any) => {
    setRoleName(event.target.value)
    if (event.target.value.length >= 3) {
      setRoleNameError('')
    } else {
      setRoleNameError(dic.integrations.instructions.aws.cloud_formation_stack_set.role_name.invalid)
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const onBackIconClick = () => {
    navigate(_.replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', INTEGRATIONS.aws.type))
  }

  const handleRoleArnSubmit = async (event: any) => {
    event.preventDefault()
    setCreateLoading(true)
    try {
      let payload

      if (tabValue === 0 || tabValue === 1) {
        setRoleArnError(null)
        payload = {
          name: integrationName,
          type: INTEGRATIONS.aws.type,
          details: {
            roleArn: roleArn,
            externalId: externalId,
          },
        }
      } else {
        setStackSetArnError(null)
        setStackSetRoleArnError(null)
        setRoleNameError(null)
        payload = {
          name: STACKSET_PARAMS.INTEGRATION_NAME,
          type: INTEGRATIONS.aws.type,
          details: {
            type: STACKSET_PARAMS.TYPE,
            roleName,
            stackSetRoleArn,
            stackSetArn,
            externalId: externalId,
          },
        }
      }

      const response = await getAxiosInstance().post('/integration', payload)

      if (response.status === 200) {
        navigate(_.replace(ROUTES.INTEGRATIONS.BY_TYPE, ':type', INTEGRATIONS.aws.type))
      } else {
        throw new Error('Failed to assume role')
      }
    } catch (error: any) {
      setCreateLoading(false)
      if (tabValue === 0 || tabValue === 1) {
        setRoleArnError(dic.integrations.instructions.aws.step_role_arn.role_arn.failed)
      }
      showNotification(dic.integrations.instructions.aws.failed, NOTIFICATION_TYPES.ERROR)
    } finally {
      setCreateLoading(false)
    }
  }

  const isCreateButtonDisabled = () => {
    let result

    if (tabValue === 0 || tabValue === 1) {
      result = loading || !integrationName || !!integrationNameError || !roleArn || !!roleArnError
    } else {
      result = loading || !roleName || !!roleNameError || !stackSetArn || !!stackSetArnError || !stackSetRoleArn || !!stackSetRoleArnError
    }

    return result
  }

  return (
    <Grid>
      <Box display="flex" flexDirection="row" alignItems="center" gap="20px">
        <IconButton onClick={onBackIconClick}>
          <ArrowBack />
        </IconButton>
        <h1>{dic.integrations.instructions.aws.title}</h1>
      </Box>

      <span> {dic.integrations.instructions.aws.select_method} </span>

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Tabs" sx={{ pb: '20px', pt: '20px' }}>
        <Tab icon={<Person />} iconPosition="start" label={dic.integrations.instructions.aws.cloud_formation.title} />
        <Tab icon={<Person />} iconPosition="start" label={dic.integrations.instructions.aws.manual.title} />
        <Tab icon={<Groups />} iconPosition="start" label={dic.integrations.instructions.aws.cloud_formation_stack_set.title} />
      </Tabs>

      <Card variant="outlined" sx={{ borderRadius: '15px' }}>
        <CardContent>
          {(tabValue === 0 || tabValue === 1) && (
            <Grid container flexDirection="column" alignItems="start">
              <span> {dic.integrations.instructions.aws.fill_integration_name} </span>
              <TextField
                label={dic.integrations.instructions.aws.integration_name}
                variant="standard"
                value={integrationName}
                onChange={handleIntegrationNameChange}
                error={!!integrationNameError}
                helperText={integrationNameError}
                required
                onBlur={validateIntegrationName}
                sx={{ mb: '10px', mt: '10px', minWidth: '300px', minHeight: '72px' }}
              />
            </Grid>
          )}

          {tabValue === 0 && <AwsIntegrationCloudFormationInstructions externalId={externalId} />}
          {tabValue === 1 && <AwsIntegrationManualInstallationInstructions externalId={externalId} />}
          {tabValue === 2 && (
            <AwsIntegrationCloudFormationStackSetInstructions
              externalId={externalId}
              roleName={roleName}
              roleNameError={roleNameError}
              handleRoleNameChange={handleRoleNameChange}
              stackSetRoleArn={stackSetRoleArn}
              stackSetRoleArnError={stackSetRoleArnError}
              handleStackSetRoleArnChange={handleStackSetRoleArnChange}
              stackSetArn={stackSetArn}
              stackSetArnError={stackSetArnError}
              handleStackSetArnChange={handleStackSetArnChange}
            />
          )}

          <form onSubmit={handleRoleArnSubmit}>
            <Grid container flexDirection="column" alignItems="start">
              {(tabValue === 0 || tabValue === 1) && (
                <React.Fragment>
                  <h3>
                    {tabValue === 0
                      ? dic.integrations.instructions.aws.step_role_arn.title_cloud_formation
                      : dic.integrations.instructions.aws.step_role_arn.title_manual}
                  </h3>
                  <span>{dic.integrations.instructions.aws.step_role_arn.role_arn.title} </span>

                  <TextField
                    label={dic.integrations.instructions.aws.step_role_arn.role_arn.input}
                    variant="standard"
                    value={roleArn}
                    onChange={handleRoleArnChange}
                    error={!!roleArnError}
                    helperText={roleArnError}
                    required
                    sx={{ minWidth: '300px', minHeight: '72px', mt: '10px' }}
                  />
                </React.Fragment>
              )}

              <Grid container flexDirection="row" justifyContent="flex-end" alignItems="flex-end" sx={{ mt: '20px' }}>
                <Button variant="text" color="primary" sx={{ mr: '20px' }} onClick={onBackIconClick}>
                  {dic.integrations.instructions.aws.cancel}
                </Button>
                {!createLoading && (
                  <Button type="submit" variant="contained" color="primary" disabled={isCreateButtonDisabled()}>
                    {dic.integrations.instructions.aws.create}
                  </Button>
                )}
                {createLoading && <CircularProgress color="primary" />}
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default AwsIntegrationInstructions
