import styled from '@emotion/styled'
import { styled as muiStyled } from '@mui/material/styles'
import { TableCell } from '@mui/material'

export const TechnologyIcon = styled.img`
  height: 36px;
`

export const IconTableCell = muiStyled(TableCell)`
  width: 120px;
`
